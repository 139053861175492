<template>
	<PageLayout :title="$t('withdrawal.title')">
		<div v-if="loading" class="loader-wrapper">
			<loader />
		</div>
		<div v-if="!loading">
			<div class="cashier-box">
				<form @submit.prevent="submit">
					<div class="small-width">
						<div v-if="wallet !== null">
							<WithdrawalStats :currency="wallet.currency" :max-amount="wallet.balance" :bonus="wallet.credit" />
						</div>
						<div class="input-group">
							<input
								v-model="form.walletAddress"
								type="text"
								:placeholder="$t('withdrawal.btc-address')"
								class="cashier-form-control"
							/>
						</div>
						<FormError :error="formErrors.walletAddress" />

						<div class="input-group">
							<input
								v-model="form.amount"
								type="text"
								:placeholder="[
									[
										$t('withdrawal.max-value', {
											value: form.maxAmount,
											currency: form.currency,
										}),
									],
								]"
								class="cashier-form-control"
							/>
						</div>

						<FormError :error="formErrors.amount" />

						<button type="submit" class="button primary-button button-margin">
							<spinner v-if="withdrawalLoading" small />
							{{ $t('withdrawal.withdraw') }}
						</button>
						<p class="fineprint fineprint-margin">* {{ $t('withdrawal.bonus') }}</p>
						<pending-withdrawal />
					</div>
				</form>
			</div>
		</div>
	</PageLayout>
</template>
<script>
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../../../api';

const loader = () => import('../../../../shared/loader/Loader.vue');
const spinner = () => import('../../../../shared/spinner/Spinner');
const FormError = () => import('../../../../shared/forms/FormError.vue');
const WithdrawalStats = () => import('../../../../shared/withdrawal-info/WithdrawalStats.vue');
const PendingWithdrawal = () => import('../PendingWithdrawal.vue');

export default {
	name: 'Withdrawal',
	components: {
		PageLayout,
		spinner,
		loader,
		FormError,
		WithdrawalStats,
		PendingWithdrawal,
	},
	data() {
		return {
			form: {
				amount: null,
				walletAddress: null,
				maxAmount: null,
				currency: 'USD',
				provider: 'Trustology',
				bonus: null,
				walletId: null,
			},
			formErrors: {
				amount: null,
			},
			withdrawalLoading: false,
			loading: false,
			wallet: null,
		};
	},
	async mounted() {
		await this.getUserWallet();
	},
	methods: {
		async getUserWallet() {
			try {
				this.wallet = await apiClient.fetchFullWallet(this.$route.params.walletId);
				this.form.walletId = this.wallet.id;
			} catch (e) {
				this.error = 'wallets.failed-to-load-wallet';
			} finally {
				this.loading = false;
			}
		},
		async submit() {
			this.formErrors = {};
			this.withdrawalLoading = true;
			try {
				const response = await apiClient.withdraw(this.form);

				if (response.status === 201) {
					this.withdrawalLoading = false;

					this.$router.push({
						path: `/payments/withdrawal/${this.$route.params.walletId}/confirmation`,
						query: { status: 'success' },
					});
				}
			} catch (error) {
				this.withdrawalLoading = false;

				if (error.response?.status === 422 && error.response?.data?.errors) {
					error.response.data.errors.map((value) => {
						this.formErrors[value.param] = value.msg;
					});
				} else {
					this.$router.push({
						path: `/payments/withdrawal/${this.$route.params.walletId}/confirmation`,
						query: { status: 'error' },
					});

					console.error('Withdrawal request failed', error);
				}
			}
		},
	},
};
</script>
<style scoped>
.fineprint {
	color: #a3a3a5;
	margin-top: 16px;
	font-size: 0.9rem;
}

.button-margin {
	margin: 0 auto !important;
	margin-top: 24px !important;
}
</style>
